import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Gmap.css';
import '../Contact.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

export default function Hula(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="hula lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Schedule</p>
                            <h2 className="lesson__top__title">レッスンカレンダー<br /><span style={{fontSize: '13px',color:'black',}}>※デンマーク体操は<a href="../trial" style={{color: 'darkorange',textDecoration:'underline'}}>体験レッスン申込み専用カレンダー</a>からお申し込みください。</span></h2>
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <h4 class="row justify-content-center">※現在レッスンカレンダーは工事中です。</h4>
                </div>

                <div className="container">
                <h3 className="subtitle text_center">体験レッスン申込み専用カレンダー</h3>
                <Yoyaku />
                </div>
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}